






















































































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'
import moment from 'moment'

@Component({ name: 'RoleSetting', components: { } })
export default class RoleSetting extends VueBase {
  private tableData = {
    data: [],
    currentPage: 1,
    pageSize: 10,
    total: 0
  }
  handleAdd() {
    this.$router.push({name: 'addRole'})
  }
  
  async handleDelete(row: any) {
      let { status } = await this.services.authority.deleteRole((row.id))
      status === 201 ? this.$message.success('角色删除成功') : this.$message.error('角色删除失败，请重试')
      if (status === 203) {
        this.$msgbox({
          showCancelButton: true,
          cancelButtonText: '取消',
          confirmButtonText: '确定',
          cancelButtonClass: "cancelButtonClass",
          showClose: false,
          dangerouslyUseHTMLString: true,
          message: `
            <div class="title">
              <i class="el-icon-warning"></i>
              当前角色有关联账号，无法删除
            </div>
            <div class="tip">
              请确认当前角色无关联账号后，再尝试删除
            </div>
          `,
        }).then(async () => {})
      }
      this.getList()
  }

  async handleStatus(row :any) {
    let msg: string = ''
    let status: number = 0
    if(row.status == 0) {
      msg = '启用'
      status = 1
    } else {
      msg = '禁用'
      status = 0
    }
      const res: any = await this.services.authority.changeRoleStatus(row.id, {
        status: status
      })
      // console.log(res)
      res.status === 201 ? this.$message.success(`角色${msg}成功`) : this.$message.error(`角色${msg}失败，请重试`)
      if (res.status === 203) {
        this.$msgbox({
          showCancelButton: true,
          cancelButtonText: '取消',
          confirmButtonText: '确定',
          cancelButtonClass: "cancelButtonClass",
          showClose: false,
          dangerouslyUseHTMLString: true,
          message: `
            <div class="title">
              <i class="el-icon-warning"></i>
              当前角色有关联账号，无法${msg}
            </div>
            <div class="tip">
              请确认无当前角色关联账号后，再尝试${msg}
            </div>
          `,
        }).then(async () => {})
      }
      this.getList()
  }

  handleEdit(row: any) {
    this.$router.push({
      name: 'addRole',
      query: {
        id: row.id,
        preset: row.is_preset
      }
    })
  }

  async getList() {
    const { data, page, status } = await this.services.authority.getRoleList({
      page: this.tableData.currentPage,
      page_size: this.tableData.pageSize
    })
    if (status !== 201) return
    this.tableData.data = data
    this.tableData.total = page.alltotal
  }

  handleCurrentChange(val: any) {
    this.tableData.currentPage = val
    this.getList()
  }

  created() {
    this.getList()
  }
}
