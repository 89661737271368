

















import VueBase from '@/VueBase'
import { Component, Watch } from 'vue-property-decorator'
import moment from 'moment'
import RoleSetting from './components/roleSetting.vue'
// import Team from './components/teamTab.vue'
import Account from './components/account.vue'

// Component.registerHooks([
//   'beforeRouteEnter',
// ])
@Component({
  name: 'Authority',
  components: {
    RoleSetting,
    // Team,
    Account,
  },
})
export default class Authority extends VueBase {
  // beforeRouteEnter(to: any, from: any, next: any) {
  //   next((vm: any) => {
  //     vm.activeName = vm.$route.name
  //     console.log(vm.$route.name)
  //   })
  // }
  private activeName = this.$route.name
  handleClick(item: any) {
    // console.log('item', item.name)
    this.$router.push({ name: item.name })
  }

  get rightsRouters() {
    return this.$store.getters.rightsRouters || []
  }
}
